import React from "react";
import { Grid, Col, Row } from "react-bootstrap";

const Help = () => (
    <Grid>
        <Row>
            <Col xs={12} className="text-center">
                <p>youtube embed here!</p>
            </Col>
        </Row>
    </Grid>
)

export default Help;